import React, {useEffect} from "react";

import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";

import PaymentStripe from "./PaymentStripe";
import {apiUrlPublic, stripeApiKey} from "../../../../helpers/apiUrl";

const StripeWrapper = () => {
    // useEffect(() => {
    //     console.log("stripe_key: ", stripeApiKey);
    //     console.log("apiUrlPublic: ", apiUrlPublic);
    // }, []);

    const stripePromise = loadStripe(stripeApiKey);
    return (
        <Elements stripe={stripePromise}>
            <PaymentStripe/>
        </Elements>
    );
};

export default StripeWrapper;
